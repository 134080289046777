import React, {Fragment} from 'react';

import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Footer from "../components/Footer";
import {Col, Image, Row} from "react-bootstrap";

const Home: React.FC = () => {
    return (
        <Fragment>
            <div className="background">

                {/* MASTHEAD*/}
                <div className="masthead">
                    <Container fluid className="text-center align-content-center text-white h-100">
                        <div className="header-text text-center">
                            <h1 className="fs-1">
                                Adria Stubbs Counselling
                                <span className="d-block fs-3 subheader">Individual & Couples Therapy</span>
                                <span className="d-block fs-3 subheader">in Edinburgh & East Lothian</span>
                            </h1>

                            <Button className="m-4 text-danger-emphasis px-4" size="lg" variant="primary"
                                    onClick={() => {
                                        const contactForm = document.getElementById("contact-form");
                                        if (contactForm) {
                                            contactForm.scrollIntoView({behavior: 'smooth'});
                                        } else {
                                            console.error("No contact form found");
                                        }
                                    }}
                            >Contact Me</Button>
                        </div>

                    </Container>
                </div>

                {/* INTRODUCTION SECTION */}

                <Container>
                    {/* ICONS*/}
                    <div className="bg-primary mt-4 m-2 p-2 pb-3 rounded-4">
                        <Row className="text-center">
                            <Col>
                                <div>
                                    <img src="/images/one_on_one_therapy.png" alt="One-on-one therapy"
                                         className="homeicon"/>
                                    <h2>One-to-one therapy</h2>
                                </div>
                            </Col>
                            <Col>
                                <img src="/images/couples_therapy.png" className="homeicon" alt="Couples Therapy"/>
                                <h2>Couples Therapy</h2>
                            </Col>
                            <Col>
                                <img src="/images/family_constellations.png" className=" homeicon"
                                     alt="Systemic Constellations"/>
                                <h2>Systemic Constellations</h2>
                            </Col>

                        </Row>
                    </div>


                    {/* TEXT */}
                    <Row className="justify-content-center text-center">
                        <Col xs={10} md={8} lg={6} className="text-center">
                            <h2 className="m-4 fs-1 quicksand-bold">Welcome to my counselling website </h2>
                            <p>I am an experienced therapist working in Edinburgh and East Lothian. I originally
                                trained in
                                psychodynamic and person-centred counselling at The University of Edinburgh. I’ve recently broadened my professional work to include systemic constellations therapy
                                (sometimes called family constellations) and couples/relationship therapy.</p>
                            <p>I aim to provide a safe, welcoming and confidential space in which to explore my
                                client’s
                                uniquely individual concerns. I use my own creativity to tune in to the needs of my
                                clients, supporting them in telling their story and exploring their feelings in whatever
                                way feels right for
                                them.</p>

                            <p>I currently work in-person and online in Edinburgh and East Lothian (Gullane). For both individuals and couples I usually offer the initial
                                session online. I sometimes work outdoors. Weekly individual
                                sessions are 50 minutes. Couples sessions are of differing lengths of time and
                                frequency, depending on the needs of each couple.</p>

                            <Button href="/mypractise" variant="primary" className="w-auto m-3 mb-5" size="lg">My
                                Practice</Button>
                        </Col>
                    </Row>
                    <Row className="justify-content-center text-center">
                        <Image src="/images/bacp-logo.png" className="bacp mb-5"></Image>
                    </Row>

                </Container>
            </div>

            <footer id="contact-form">
                <Footer/>
            </footer>
        </Fragment>
    )
}

export default Home;

